import styled from 'styled-components';
import { BaseButton } from '@once/components/Primitive/Button';

export const VideoStoryContainer = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const VideoControl = styled(BaseButton)`
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  top: 62px;
  right: 8px;
`;
