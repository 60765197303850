import { css } from 'styled-components';

export const noScrollBarCSS = css`
  /* IE and Edge */
  -ms-overflow-style: none;

  /* This is for Firefox */
  scrollbar-width: none;

  /* This is for everyone else */
  &::-webkit-scrollbar {
    display: none;
  }
`;
