// once kit
// import {  } from '@once/.....';

// custom components
import { Text } from '@custom/components/common/Text';
import { Image } from '@once/components/Primitive/Image';
import { Flex } from '@once/components/Layout/Flex';

export default function ReassuranceSection(): JSX.Element {
  return (
    <div className="relative bg-black/95 h-full">
      <Flex fillParent justifyContent="center">
        <div className="p-8">
          <Flex alignItems="center">
            <Text className="text-purple-rain pb-2" size="small">
              WHAT WE DO AT NEXTSTORE
            </Text>
            <Text className="text-beige text-center" size="subtitle_highlight">
              nextstore is a website builder that turns your shop into a conversion -oriented machine.
            </Text>
          </Flex>
        </div>
        <div className="overflow-scroll w-full h-12 px-6">
          <Image
            image={{
              id: 'logo--brand',
              url: '/logo--brand@2x.png',
              altText: 'logo--brand',
              width: 1141,
              height: 96,
              blurDataURL: null,
            }}
            wrapper={{ height: '100%', width: '570px' }}
            layout="fill"
            objectFit="cover"
          />
        </div>
      </Flex>
    </div>
  );
}
