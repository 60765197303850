// once kit
// import {  } from '@once/.....';

// custom components
import { Flex } from '@once/components/Layout/Flex';
import { HalfContentCarousel } from '@custom/components/mobile/HalfContentCarousel/HalfContentCarousel';

export default function ValueProductSection(): JSX.Element {
  return (
    <Flex fillParent>
      <HalfContentCarousel
        carouselID="valueProductSection"
        screens={[
          {
            background: 'bg-cashmere-blue',
            overtitle: 'OUR BUILDER',
            title: 'Plug your backend, play with your frontend.',
            text: [
              `You can be on Shopify, BigCommerce or any other e-commerce platform or CMS. It's a 'plug your backend and play with your frontend' solution`,
            ],
            image: {
              id: 'how--plug-play--desk',
              url: '/how--plug-play--desk@2x.png',
              altText: 'how--plug-play--desk',
              width: 1901,
              height: 926,
              blurDataURL: null,
              objectPosition: '0 25%',
            },
          },
          {
            background: 'bg-greige',
            overtitle: 'OUR BUILDER',
            title: 'Access ready-to-use mobile-first components',
            text: [
              `From powerful native like blocks (Drawers, Bottom Navigation) to advanced gestures (Swipe, Pinch, Tiktok like navigation), leverage the power of mobile through our extensive library.`,
            ],
            image: {
              id: 'how--mobile-components',
              url: '/how--mobile-components@2x.png',
              altText: 'how--mobile-components',
              width: 1066,
              height: 1536,
              blurDataURL: null,
              objectPosition: '0 -15%',
            },
          },
          {
            background: 'bg-beige',
            overtitle: 'OUR BUILDER',
            title: 'Set pages live in seconds',
            text: [
              `Our workflow allows you to test changes on a staging environment so nothing brakes and publish everything live right after`,
            ],
            image: {
              id: 'how--publish--desk',
              url: '/how--publish--desk@2x.png',
              altText: 'how--publish--desk',
              width: 1622,
              height: 984,
              blurDataURL: null,
              objectPosition: '0 25%',
            },
          },
        ]}
      />
    </Flex>
  );
}
