import { useEffect, useRef, useState } from 'react';

import { VolumeOffIcon, VolumeOnIcon } from '@once/components/icons';

import { VideoControl, VideoStoryContainer } from './VideoStory.styles';

export type VideoStoryProps = {
  sources: Array<{
    url: string;
    type: string;
  }>;
  autoPlay?: boolean;
};

export function VideoStory({ sources, autoPlay }: VideoStoryProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (!videoRef.current) {
      return undefined;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const [element] = entries;

        if (!element.isIntersecting && !videoRef.current?.paused) {
          videoRef.current?.pause();
          setIsMuted(true);
        }

        if (element.isIntersecting && videoRef.current?.paused) {
          videoRef.current.play().catch((error) => {
            console.log('VIDEO ERROR', error);
          });
        }
      },
      { threshold: 0.9 },
    );

    observer.observe(videoRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <VideoStoryContainer ref={videoRef} loop playsInline muted={isMuted} autoPlay={!!autoPlay}>
        {sources.map((source) => (
          <source key={source.type} src={source.url} type={source.type} />
        ))}
      </VideoStoryContainer>
      <VideoControl
        aria-label="Mute/unmute button"
        onClick={() => {
          setIsMuted(!isMuted);
        }}
      >
        {isMuted ? (
          <VolumeOffIcon mainColor="#ffffff" height={24} width={24} />
        ) : (
          <VolumeOnIcon height={24} width={24} mainColor="#ffffff" />
        )}
      </VideoControl>
    </>
  );
}
