import { useSpring, config, SpringValue, SpringRef } from '@react-spring/web';
import { useCallback, useEffect, useRef } from 'react';
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';

import { ScrollToTopEvent, SnapScrollInfoEvent, SnapScrollToSectionEvent } from '@once/events';

type UseSpringSnapArgs = {
  id: string;
  lastIndex: number;
  screenBound?: number;
  speedBound?: number;
  orientation?: 'vertical' | 'horizontal';
  ratio: number;
  disableOnTap?: boolean;
};

type UseSpringSnap = {
  handlers: SwipeableHandlers;
  snapSpring: { y: SpringValue<number> } | { x: SpringValue<number> };
  snapAPI: SpringRef<{ y: number }> | SpringRef<{ x: number }>;
};

export function useSpringSnap({
  id,
  lastIndex,
  screenBound = 0.15,
  speedBound = 0.65,
  orientation = 'vertical',
  ratio,
  disableOnTap,
}: UseSpringSnapArgs): UseSpringSnap {
  const screen = useRef(0);

  const [verticalSnap, verticalAPI] = useSpring(() => ({ y: 0 }));
  const [horizontalSnap, horizontalAPI] = useSpring(() => ({ x: 0 }));

  const verticalGoToScreenCurrent = useCallback(
    (isInstant = false) => {
      if (typeof window !== 'undefined') {
        const y = -window.innerHeight * ratio * screen.current;
        if (isInstant) {
          verticalAPI.set({ y });
        } else {
          verticalAPI.start({ y, config: { ...config.stiff, clamp: true } });
        }
        document.dispatchEvent(new SnapScrollInfoEvent(id, { position: screen.current }));
      }
    },
    [id, ratio, verticalAPI],
  );

  const horizontalGoToScreenCurrent = useCallback(
    (isInstant = false) => {
      if (typeof window !== 'undefined') {
        const x = -window.innerWidth * screen.current * ratio;
        if (isInstant) {
          horizontalAPI.set({ x });
        } else {
          horizontalAPI.start({ x, config: { ...config.stiff, clamp: true } });
        }
        document.dispatchEvent(new SnapScrollInfoEvent(id, { position: screen.current }));
      }
    },
    [id, ratio, horizontalAPI],
  );

  const verticalHandlers = useSwipeable({
    onSwiping: ({ deltaY, dir }) => {
      if (dir === 'Left' || dir === 'Right') {
        return;
      }
      if ((screen.current === 0 && dir === 'Down') || (screen.current === lastIndex && dir === 'Up')) {
        return;
      }
      verticalAPI.set({ y: -window.innerHeight * ratio * screen.current + deltaY });
    },
    onSwipedUp: ({ absY, velocity }) => {
      if (screen.current === lastIndex) {
        return;
      }

      const overBound = absY >= window.innerHeight * screenBound;
      const isFastSwipe = velocity >= speedBound;
      if (overBound || isFastSwipe) {
        screen.current += 1;
      }
      verticalGoToScreenCurrent();
    },
    onSwipedDown: ({ absY, velocity }) => {
      if (screen.current <= 0) {
        return;
      }

      const overBound = absY >= window.innerHeight * screenBound;
      const isFastSwipe = velocity >= speedBound;
      if (overBound || isFastSwipe) {
        screen.current -= 1;
      }
      verticalGoToScreenCurrent();
    },
  });

  const horizontalHandlers = useSwipeable({
    onSwiping: ({ deltaX, dir }) => {
      if (dir === 'Up' || dir === 'Down') {
        return;
      }
      const pos = screen.current * -window.innerWidth * ratio + deltaX;

      if (dir === 'Right' && screen.current === 0 && pos > 0) {
        return;
      }
      if (dir === 'Left' && screen.current === lastIndex) {
        return;
      }
      horizontalAPI.set({ x: pos });
    },
    onSwipedLeft: ({ absX, velocity }) => {
      if (screen.current === lastIndex) {
        return;
      }

      const overBound = absX >= window.innerWidth * screenBound;
      const isFastSwipe = velocity >= speedBound;
      if (overBound || isFastSwipe) {
        screen.current += 1;
      }
      horizontalGoToScreenCurrent();
    },
    onSwipedRight: ({ absX, velocity }) => {
      if (screen.current === 0) {
        return;
      }

      const overBound = absX >= window.innerWidth * screenBound;
      const isFastSwipe = velocity >= speedBound;
      if (overBound || isFastSwipe) {
        screen.current -= 1;
      }
      horizontalGoToScreenCurrent();
    },
    onTap: ({ event }) => {
      if (disableOnTap || !(event instanceof TouchEvent)) {
        return;
      }

      const { clientX } = event.changedTouches[0];

      const thirdScreen = window.innerWidth / 3;
      if (clientX > thirdScreen && screen.current < lastIndex) {
        screen.current += 1;
      } else if (clientX > thirdScreen && screen.current === lastIndex) {
        screen.current = 0;
      } else if (clientX <= thirdScreen && screen.current > 0) {
        screen.current -= 1;
      }
      horizontalGoToScreenCurrent();
    },
  });

  useEffect(
    function listenToScrollToTopEvent() {
      function scrollToTop(e: Event): void {
        if (e instanceof ScrollToTopEvent) {
          screen.current = 0;
          verticalGoToScreenCurrent(true);
        }
      }

      document.addEventListener(ScrollToTopEvent.eventName, scrollToTop);
      return () => document.removeEventListener(ScrollToTopEvent.eventName, scrollToTop);
    },
    [verticalGoToScreenCurrent],
  );

  useEffect(
    function listenToScrollToSectionEvent() {
      function scrollToSection(e: Event): void {
        if (e instanceof SnapScrollToSectionEvent) {
          // Update screen.current if the value seems legit
          if (e.detail.index >= 0 && e.detail.index <= lastIndex) {
            screen.current = e.detail.index;
          }

          // trigger animation
          if (orientation === 'vertical') {
            verticalGoToScreenCurrent();
          } else {
            horizontalGoToScreenCurrent();
          }
        }
      }

      document.addEventListener(SnapScrollToSectionEvent.eventName(id), scrollToSection);
      return () => document.removeEventListener(SnapScrollToSectionEvent.eventName(id), scrollToSection);
    },
    [id, lastIndex, orientation, verticalGoToScreenCurrent, horizontalGoToScreenCurrent],
  );

  if (orientation === 'vertical') {
    return {
      snapSpring: verticalSnap,
      snapAPI: verticalAPI,
      handlers: verticalHandlers,
    };
  }

  return {
    snapSpring: horizontalSnap,
    snapAPI: horizontalAPI,
    handlers: horizontalHandlers,
  };
}
