import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { noScrollBarCSS } from '@once/components/Primitive/NoScrollBar';

type VerticalSnapContainerProps = {
  $bgColor?: string;
};

export const VerticalSnapContainer = styled.div<VerticalSnapContainerProps>`
  position: relative;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  background: ${(p) => p?.$bgColor};
`;

export const VerticalSnapRail = styled(animated.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type VerticalSectionProps = {
  $ratio: number;
};

export const VerticalSection = styled.section<VerticalSectionProps>`
  height: calc(${(p) => p.$ratio} * (100vh - var(--mobile-delta)));
`;

type HorizontalSectionProps = {
  $ratio: number;
};

export const HorizontalSection = styled.section<HorizontalSectionProps>`
  width: calc(${(p) => p.$ratio} * 100vw);
  height: 100%;
`;

type HorizontalSnapContainerProps = {
  $bgColor?: string;
};

export const HorizontalSnapContainer = styled.div<HorizontalSnapContainerProps>`
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow-x: hidden;
  background: ${(p) => p?.$bgColor};

  ${noScrollBarCSS};
`;

export const HorizontalSnapRail = styled(animated.div)`
  position: absolute;
  display: flex;
  height: 100%;
`;
