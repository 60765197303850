// once kit
// import {  } from '@once/.....';
import { RawImage } from '@once/components/Primitive/Image';

// custom components
import { Text } from '@custom/components/common/Text';
import { Flex } from '@once/components/Layout/Flex';
import { Dropout } from '@custom/components/icons/dropout';
import { WordmarkDark } from '@custom/components/icons/wordmark_dark';

export default function PreFooterSection(): JSX.Element {
  return (
    <div className="relative bg-dark h-full">
      <div className="h-full">
        <div className="w-full h-[30%] relative responsive translate-y-full">
          <div className="absolute top-0 bottom-0 left-0 right-0 m-auto w-[fit-content] h-[fit-content]">
            <WordmarkDark className="fill-beige m-auto" width="50%" />
          </div>
          <div className="opacity-50">
            <RawImage src="/next_banner.png" layout="fill" objectFit="cover" alt="/next_banner.png" />
          </div>
        </div>
      </div>
      <section className="absolute bg-beige rounded-tl-xl rounded-tr-xl bottom-0 pt-3 pr-6 pb-6 pl-6">
        <div className="absolute w-[fit-content] top-0 left-0 right-0 mx-auto" style={{ marginTop: -1 }}>
          <Dropout className="fill-dark m-auto" height={20} />
        </div>
        <div className="pt-8">
          <Flex justifyContent="center" alignItems="center" fillParent>
            <div className="pb-3">
              <Text className="text-purple-rain" size="caption">
                NOW IT&#39;S YOUR TURN
              </Text>
            </div>
            <Flex alignItems="center">
              <Text className="text-dark pb-20" size="subtitle_highlight">
                Get in touch & take your store to the next-level
              </Text>
            </Flex>
          </Flex>
        </div>
      </section>
    </div>
  );
}
