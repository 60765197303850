import styled, { css } from 'styled-components';

type NativeSnapProps = {
  $bgColor: string;
  $orientation: 'vertical' | 'horizontal';
  $reduce?: boolean;
};

export const NativeSnap = styled.section<NativeSnapProps>`
  position: relative;
  display: ${(p) => (p.$orientation === 'vertical' ? 'block' : 'flex')};
  background: ${(p) => p.$bgColor};
  overflow: auto;
  scroll-snap-type: ${(p) => (p.$orientation === 'vertical' ? 'y' : 'x')} mandatory;
  ${(p) =>
    !p?.$reduce
      ? css`
          height: 100%;
        `
      : undefined};

  /* This is for Firefox */
  scrollbar-width: none;

  /* This is for everyone else */
  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  flex-direction: ${(p) => (p.$orientation === 'vertical' ? 'column' : undefined)};
`;
