import { GetStaticProps } from 'next';

// once kit
// import {  } from '@once/.....';

// custom components
import { WordmarkDark } from '@custom/components/icons/wordmark_dark';
import { VerticalSnap } from '@once/components/Mobile/Container/SnapScroll';
import SwipableButton from '@custom/components/common/swipeable-button';
import SplashScreen from './splashScreen';
import ReassuranceSection from './reassuranceSection';
import ValueProductSection from './valueProductSection';
import ValuePropSection from './valuePropSection';
import PreFooterSection from './preFooterSection';

export const getStaticProps: GetStaticProps = async (ctx: any) => ({
  props: {
    locale: ctx.locale || null,

    // Adjust your config here! This config is used by the _app.tsx to know what behavior to have on each page.
    // All those config variables may not be used yet, check the code to ensure :)
    config: {
      head: {
        faviconURL: 'https://uploads-ssl.webflow.com/606d6deb975ce72bbba4f515/6074c6a03af1590da7bf96a0_small.png',
        title: 'Once component test page',
        description: 'Once dev environment',
        twitterCard: 'summary_large_image',
        ogImage: 'https://uploads-ssl.webflow.com/606d6deb975ce72bbba4f515/6074c6a03af1590da7bf96a0_small.png',
        ogImageAlt: 'Once',
        ogImageHeight: 32,
        ogImageWidth: 32,
      },
      header: {
        opaqueOnScroll: false,
        disabled: false,
      },
      scroll: {
        snap: true,
        hideScrollbar: true,
        disabled: false,
      },
      cart: {
        disabled: false,
      },
      menu: {
        disabled: false,
      },
      footer: {
        disabled: false,
      },
    },
  },
  revalidate: 3600,
});

export default function Page(): JSX.Element {
  const onComplete = (): void => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      (window as Window).location = 'https://calendly.com/arthurbk/nextstore-expectations';
    } else {
      window.open('https://calendly.com/arthurbk/nextstore-expectations', '_blank', 'popup');
    }
  };

  return (
    <>
      <div className="absolute top-0 pt-4 w-full z-10">
        <div className="m-auto" style={{ width: '30%' }}>
          <WordmarkDark className="fill-black" />
        </div>
      </div>
      <VerticalSnap id="snapscroll">
        <SplashScreen />
        <ReassuranceSection />
        <ValuePropSection />
        <ValueProductSection />
        <PreFooterSection />
      </VerticalSnap>
      <div className="h-12 w-full absolute bottom-0 px-4 mb-4">
        <SwipableButton boldText="" text="Build your nextstore" handleComplete={onComplete} validationText="" />
      </div>
    </>
  );
}
