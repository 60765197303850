// once kit
// import {  } from '@once/.....';

// custom components
import { Card } from '@custom/components/mobile/Card';
import { VideoStory } from '@once/components/Mobile/Primitive/VideoStory';
import { Text } from '@custom/components/common/Text';
// import { Flex } from '@once/components/Layout/Flex';

export default function SplashScreen(): JSX.Element {
  return (
    <div className="relative">
      <div style={{ height: 'calc(100vh - var(--mobile-delta))' }}>
        <VideoStory
          autoPlay
          sources={[
            {
              url: '/hero--video.mp4',
              type: 'video/mp4',
            },
          ]}
        />
      </div>
      <div className="absolute bottom-0">
        <Card classNames="bg-komorebi/30 px-3 pt-4 pb-16 m-1 backdrop-blur-sm">
          <Text className="text-white p-4" size="subtitle_highlight">
            Modern brands build outstanding shopping experiences.
          </Text>
        </Card>
      </div>
    </div>
  );
}
