import { Children } from 'react';

import { useSpringSnap } from '@once/hooks/useSpringSnap';

import { VerticalSnapContainer, VerticalSnapRail, VerticalSection } from './SnapScroll.styles';

export type VerticalSnapProps = {
  id: string;
  screenRatio?: number;
  bgColor?: string;
  disableOnTap?: boolean;
  children: React.ReactNode;
};

export function VerticalSnap({ id, screenRatio = 1, bgColor, disableOnTap, children }: VerticalSnapProps): JSX.Element {
  const lastIndex = Children.toArray(children).length - 1;
  const ratio = Math.max(0, Math.min(screenRatio, 1));

  const { handlers, snapSpring } = useSpringSnap({
    id,
    lastIndex,
    ratio,
    disableOnTap,
  });

  return (
    <VerticalSnapContainer {...handlers} $bgColor={bgColor}>
      <VerticalSnapRail style={snapSpring}>
        {Children.map(children, (child) => (
          <VerticalSection $ratio={ratio}>{child}</VerticalSection>
        ))}
      </VerticalSnapRail>
    </VerticalSnapContainer>
  );
}
