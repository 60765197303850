// once kit
// import {  } from '@once/.....';

// custom components
import { Flex } from '@once/components/Layout/Flex';
import { HalfContentCarousel } from '@custom/components/mobile/HalfContentCarousel/HalfContentCarousel';

export default function ValuePropSection(): JSX.Element {
  return (
    <Flex fillParent>
      <HalfContentCarousel
        carouselID="valuePropSection"
        screens={[
          {
            background: 'bg-cashmere-blue',
            overtitle: 'WHY NEXTSTORE',
            title: 'Create a unique experience for each device',
            text: [
              `A dedicated frontend for your mobile shop and another for desktop, enabling a mobile-first approach. The traditional responsive approach simply does not make the cut anymore.`,
            ],
            image: {
              id: 'benefit--perf--desk',
              url: '/benefit--perf--desk@2x.png',
              altText: 'benefit--perf--desk',
              width: 1066,
              height: 1420,
              blurDataURL: null,
              objectPosition: 'top',
            },
          },
          {
            background: 'bg-greige',
            overtitle: 'WHY NEXTSTORE',
            title: 'Unlock great page performances',
            text: [
              `We’ve got your back on the tech side so you don’t have to bother: from instant loading images to
            pre-built website, everything has been designed to offer superior page performance scoring`,
            ],
            image: {
              id: 'benefit--mobile',
              url: '/benefit--mobile@2x.png',
              altText: 'benefit--mobile',
              width: 1066,
              height: 1418,
              blurDataURL: null,
              objectPosition: 'top',
            },
          },
          {
            background: 'bg-beige',
            overtitle: 'WHY NEXTSTORE',
            title: 'Iterate in a few days, not weeks',
            text: [
              `Our drag and drop builder along with our ready-to-use library of component allows you to AB test new
              experiences in a few days, no more back and forth during weeks with your agency: the best recipe to
              optimise your conversion rates.`,
            ],
            image: {
              id: 'benefit--empowering--desk',
              url: '/benefit--empowering--desk@2x.png',
              altText: 'benefit--empowering--desk',
              width: 1066,
              height: 1420,
              blurDataURL: null,
              objectPosition: 'top',
            },
          },
        ]}
      />
    </Flex>
  );
}
